// @flow strict-local
import * as React from "react";

import { useMediaQuery } from "react-responsive";

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1279px)" });

const TabletAndBelow = ({
  children,
}: {
  children: React.ChildrenArray<React.Node>,
}): React.Node => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();

  return isTabletAndBelow ? <div className="mobile">{children}</div> : null;
};

export default TabletAndBelow;
