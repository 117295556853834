// @flow strict-local
import * as React from "react";

import { useMediaQuery } from "react-responsive";

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1280px)" });

const Desktop = ({
  children,
}: {
  children: React.ChildrenArray<React.Node>,
}): React.Node => {
  const isDesktop = useDesktopMediaQuery();

  return isDesktop ? children : null;
};

export default Desktop;
