// @flow strict-local

import * as React from "react";

import { Trans, useTranslation } from "react-i18next";

import { Grid } from "antd-mobile";
import moment from "moment";

const MobileFooter = (): React.Node => {
  const { t } = useTranslation();
  const year = moment().year();

  return (
    <Grid columns={24} gap={8} className="p-a-8 m-footer">
      <Grid.Item span={24} className="text-center">
        <Trans
          i18nKey="footer"
          components={{
            "1": <a className="p-l-8 p-r-8" href="/terms" target="_blank" />,
            "2": <a className="p-l-8 p-r-8" href="/privacy" target="_blank" />,
          }}
          values={{ year: year }}
        />
      </Grid.Item>
    </Grid>
  );
};

export default MobileFooter;
