// @flow strict-local
import * as React from "react";

import { Spin } from "antd";

const DesktopLoadingIndicator = ({
  style,
}: {
  style?: { [string]: string },
}): React.Node => {
  return (
    <div className="center-div m-t-24 m-b-24" style={style}>
      <Spin />
    </div>
  );
};

export default DesktopLoadingIndicator;
