// @flow
import { Environment, Network, RecordSource, Store } from "relay-runtime";

import Cookies from "js-cookie";
import type { IEnvironment } from "relay-runtime";

function fetchQuery(operation, variables) {
  return fetch("/logged_out_graphql/", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json && json.errors) {
        console.log(json.errors[0]);
        throw new Error(json.errors[0].message);
      }
      return json;
    });
}

const env: Environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default env;
