// @flow
import "../css/app.scss";

import { ConfigProvider } from "antd";
import Desktop from "../js/util/Desktop";
import DesktopLoadingIndicator from "../js/common/DesktopLoadingIndicator";
import HttpApi from "i18next-http-backend";
import LandingPage from "../logged_out_js/landing_page/LandingPage";
import LanguageDetector from "i18next-browser-languagedetector";
import MagisterLoggedOutRelayEnvironment from "../logged_out_js/util/MagisterLoggedOutRelayEnvironment";
import MobileLandingPage from "../logged_out_js/landing_page/MobileLandingPage";
import MobileLoginPage from "../js/login/MobileLoginPage";
import { PRIMARY_COLOR } from "../js/util/StyleConsts";
// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactDOM from "react-dom";
import { RelayEnvironmentProvider } from "react-relay";
import { Suspense } from "react";
import TabletAndBelow from "../js/util/TabletAndBelow";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import nullthrows from "nullthrows";

ConfigProvider.config({
  theme: {
    linkColor: PRIMARY_COLOR,
    primaryColor: PRIMARY_COLOR,
  },
});

i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/static/public/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: window.jsData.default_language_code ?? "en",
    lng:
      window.jsData?.user_profile?.language_code ??
      window.jsData.default_language_code ??
      undefined,
    load: "languageOnly",
  });

ReactDOM.render(
  <RelayEnvironmentProvider environment={MagisterLoggedOutRelayEnvironment}>
    <Suspense fallback={<DesktopLoadingIndicator />}>
      <Desktop>
        <LandingPage />
      </Desktop>
      <TabletAndBelow>
        <MobileLandingPage />
      </TabletAndBelow>
    </Suspense>
  </RelayEnvironmentProvider>,
  nullthrows(document.getElementById("root"))
);
