// @flow strict-local
import * as React from "react";

import { Button, Form, Grid, Input, Space } from "antd-mobile";
import { Suspense, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import Cookies from "js-cookie";
import DesktopLoadingIndicator from "../common/DesktopLoadingIndicator";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import MobileFooter from "../common/MobileFooter";
import nullthrows from "fbjs/lib/nullthrows";

const MobileLoginPage = (): React.Node => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const isKnowlificSite = window.jsData.site_name == "Knowlific";

  return (
    <>
      <Grid columns={24} offset={8}>
        <Grid.Item span={24} className="p-a-20">
          <Grid columns={24} className="m-t-0">
            <Grid.Item span={24} className="text-center">
              <img src={window.jsData.logo_url} width={130} />
            </Grid.Item>
          </Grid>
          <Grid columns={24}>
            <Grid.Item span={24} className="text-center">
              <h1 className="m-b-12">{t("login.sign_in")}</h1>
              <h3 className="m-b-40">{t("login.sign_in_tagline")}</h3>
            </Grid.Item>
          </Grid>
          <form method="post" action="/accounts/login/" id="login_form">
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={Cookies.get("csrftoken")}
            />
            <input
              type="hidden"
              name="next"
              value={window?.jsData?.next ?? ""}
            />
            <Grid columns={24}>
              <Grid.Item span={24}>
                <span className="text-secondary">{t("login.username")}</span>
                <br />
                <input
                  className="m-t-8 m-input-large"
                  type="text"
                  name="username"
                  autoComplete="email"
                  autoFocus={true}
                  value={email}
                  onChange={(ev) =>
                    setEmail(ev.target?.value?.replace(" ", "") ?? "")
                  }
                />
              </Grid.Item>
            </Grid>
            <Grid columns={24} className="m-t-24">
              <Grid.Item span={24}>
                <span className="text-secondary">{t("login.password")}</span>
                <br />
                <input
                  className="m-t-8 m-input-large"
                  type="password"
                  name="password"
                  autoComplete="password"
                />
              </Grid.Item>
            </Grid>
            {Boolean(window.login_errors) &&
            (window.login_errors.__all__?.length ?? 0) > 0
              ? window.login_errors.__all__.map((err) => {
                  return (
                    <Grid columns={24} key="err.message">
                      <Grid.Item span={24}>
                        <br />
                        <span className="p-t-8 d-inline-block text-danger">
                          {err.message}
                        </span>
                      </Grid.Item>
                    </Grid>
                  );
                })
              : null}
            <Grid columns={24} className="m-t-24">
              <Grid.Item span={24} className="text-right">
                <a href="/accounts/password/reset/">
                  {t("login.forgot_password")}
                </a>
              </Grid.Item>
            </Grid>
            <Grid columns={24} className="m-t-40">
              <Grid.Item span={24}>
                <Button
                  htmlType="submit"
                  color="primary"
                  shape="round"
                  size="large"
                  block
                  onClick={() =>
                    nullthrows(document.getElementById("login_form")).submit()
                  }
                >
                  {t("login.sign_in")}
                </Button>
              </Grid.Item>
            </Grid>
            <Grid columns={24} className="m-t-24 text-center">
              <Grid.Item span={24}>{t("or")}</Grid.Item>
            </Grid>
            <Grid columns={24} className="m-t-24">
              <Grid.Item span={24}>
                <Button
                  color="default"
                  shape="round"
                  size="large"
                  block
                  onClick={() => {
                    window.location.href = "/one_time_login/";
                  }}
                >
                  {t("login.one_time_login_send")}
                </Button>
              </Grid.Item>
            </Grid>
          </form>
          {isKnowlificSite && (
            <Grid columns={4} gap={8} className="m-t-24">
              <Grid.Item span={4} className="text-center">
                {t("login.no_account_question")}
                <a href="/signup/" className="p-l-8">
                  {t("login.sign_up")}
                </a>
              </Grid.Item>
            </Grid>
          )}
        </Grid.Item>
      </Grid>
      <MobileFooter />
    </>
  );
};

export default MobileLoginPage;
