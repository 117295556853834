/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeatureToggleName = "UC_FRONT_PAGE_PANAMA_SIGNUP_BUTTON" | "%future added value";
export type useFeatureToggleQueryVariables = {|
  name: FeatureToggleName
|};
export type useFeatureToggleQueryResponse = {|
  +featureToggleStatus: ?boolean
|};
export type useFeatureToggleQuery = {|
  variables: useFeatureToggleQueryVariables,
  response: useFeatureToggleQueryResponse,
|};
*/


/*
query useFeatureToggleQuery(
  $name: FeatureToggleName!
) {
  featureToggleStatus(name: $name)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ScalarField",
    "name": "featureToggleStatus",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFeatureToggleQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFeatureToggleQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a27b6196172d7c705343a5f71e48bcb4",
    "id": null,
    "metadata": {},
    "name": "useFeatureToggleQuery",
    "operationKind": "query",
    "text": "query useFeatureToggleQuery(\n  $name: FeatureToggleName!\n) {\n  featureToggleStatus(name: $name)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27ba445a6cc239bba5000e1a277c2d52';

module.exports = node;
