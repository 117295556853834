// @flow strict-local

export const INACTIVE_LINK_COLOR = "#A5AAB4";
export const PRIMARY_COLOR: string = "#2440F1";
export const SECONDARY_COLOR: string = "#8E8993";
export const LIGHT_BG_COLOR: string = "#f9fafb";
export const LIGHT_BG_COLOR_ACTIVE: string = "#dadfff";
export const DANGER_COLOR: string = "#ff4d4f";
export const SUCCESS_COLOR: string = "#0BA737";
export const WARNING_COLOR: string = "#dfca11";
export const FEMALE_COLOR: string = "#fa34b8";
