// @flow strict-local

import type {
  FeatureToggleName,
  useFeatureToggleQuery,
} from "./__generated__/useFeatureToggleQuery.graphql";
import { graphql, useLazyLoadQuery } from "react-relay";

const useFeatureToggle = (name: FeatureToggleName): boolean => {
  const { featureToggleStatus } = useLazyLoadQuery<useFeatureToggleQuery>(
    graphql`
      query useFeatureToggleQuery($name: FeatureToggleName!) {
        featureToggleStatus(name: $name)
      }
    `,
    { name }
  );

  return featureToggleStatus ?? false;
};

export default useFeatureToggle;
