// @flow strict-local
import * as React from "react";

import { Button, Col, Input, Row, Typography } from "antd";
import { Suspense, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import Cookies from "js-cookie";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import moment from "moment";
import useFeatureToggle from "../util/useFeatureToggle";

const { Text, Link } = Typography;

const LandingPage = (): React.Node => {
  const { t } = useTranslation();
  const year = moment().year();
  const isKnowlificSite = window.jsData.site_name == "Knowlific";
  const isUltracurso = window.jsData.site_name == "UltraCurso";
  const showPanamaSignupButton =
    useFeatureToggle("UC_FRONT_PAGE_PANAMA_SIGNUP_BUTTON") && isUltracurso;

  return (
    <>
      <Row>
        <Col span={12} className="landing-left-image ">
          <img src="/static/public/img/login-left-image.jpg" />
        </Col>
        <Col span={12} className="p-a-40">
          <div className="landing-right-container">
            {isKnowlificSite && (
              <Row>
                <Col className="text-right" span={24}>
                  <span className="p-r-32">
                    {t("login.no_account_question")}
                  </span>
                  <Link href="/signup/">{t("login.sign_up")}</Link>
                </Col>
              </Row>
            )}
            <Row className="m-t-24">
              <Col span={24} className="text-center">
                <img src={window.jsData.logo_url} width={130} />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="text-center">
                <h1 className="m-b-12">{t("login.sign_in_tagline")}</h1>
              </Col>
            </Row>
            <Row className="m-t-40">
              <Col span={24}>
                {showPanamaSignupButton ? (
                  <>
                    <Button
                      htmlType="submit"
                      type="primary"
                      shape="round"
                      size="large"
                      className="width-100 m-b-24"
                      href="/beca/"
                    >
                      {t("login.panama_register_button")}
                    </Button>
                    <div className="text-center m-b-24">{t("or")}</div>
                  </>
                ) : null}
                <Button
                  htmlType="submit"
                  type={showPanamaSignupButton ? "default" : "primary"}
                  shape="round"
                  size="large"
                  className="width-100"
                  href="/accounts/login/"
                >
                  {t("login.sign_in")}
                </Button>
              </Col>
            </Row>
            <Row className="m-t-24">
              <Trans
                i18nKey="footer"
                components={{
                  "1": (
                    <a className="p-l-8 p-r-8" href="/terms" target="_blank" />
                  ),
                  "2": (
                    <a
                      className="p-l-8 p-r-8"
                      href="/privacy"
                      target="_blank"
                    />
                  ),
                }}
                values={{ year: year }}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LandingPage;
